











































import Vue from 'vue'

import MessageBus from '@/message-bus'

export default Vue.extend({
  metaInfo: {
    title: 'About',
  },
  data() {
    return {
      apiVersion: '?',
      isFetchingApiVersion: false,
      fetchingApiVersionError: undefined as undefined | Error,
    }
  },
  created() {
    this.fetchApiVersion()
  },
  methods: {
    fetchApiVersion() {
      this.isFetchingApiVersion = true
      this.fetchingApiVersionError = undefined

      this.$api
        .version()
        .then(response => {
          this.apiVersion = response
        })
        .catch(err => {
          MessageBus.error(err)
          this.fetchingApiVersionError = err
        })
        .finally(() => {
          this.isFetchingApiVersion = false
        })
    },
  },
})
